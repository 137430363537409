import BarChartIcon from '@mui/icons-material/BarChart';
import ItemIcon from '@mui/icons-material/Checkroom';
import FeedbackIcon from '@mui/icons-material/Feedback';
import UserIcon from '@mui/icons-material/People';
import ReviewsIcon from '@mui/icons-material/Reviews';
import OutfitIcon from '@mui/icons-material/Science';
import SettingsIcon from '@mui/icons-material/Settings';
import { Resource } from 'react-admin';

import { Feedback, Item, Outfit, Settings, User, UserAssessments } from '@widgets';

import { Statistics } from '@features';

import { ResourceName } from '@shared/types';

export const Resources = (
  <>
    <Resource
      name={ResourceName.Item}
      list={Item.List}
      show={Item.Show}
      create={Item.Create}
      edit={Item.Edit}
      options={{ label: 'Вещи' }}
      icon={ItemIcon}
    />

    <Resource
      name={ResourceName.Outfit}
      list={Outfit.List}
      show={Outfit.Show}
      options={{ label: 'Образы' }}
      icon={OutfitIcon}
    />

    <Resource
      name={ResourceName.Feedback}
      list={Feedback.List}
      show={Feedback.Show}
      options={{ label: 'Обратная связь' }}
      icon={FeedbackIcon}
    />

    <Resource
      name={ResourceName.User}
      list={User.List}
      show={User.Show}
      options={{ label: 'Пользователи' }}
      icon={UserIcon}
    />
    <Resource name="statistics" list={Statistics.UI} options={{ label: 'Статистика' }} icon={BarChartIcon} />

    <Resource name="settings" list={Settings.List} options={{ label: 'Настройки' }} icon={SettingsIcon} />

    <Resource
      name={ResourceName.UserAssessments}
      list={UserAssessments.List}
      show={UserAssessments.Show}
      options={{ label: 'Пользовательские оценки' }}
      icon={ReviewsIcon}
    />
  </>
);
