/* tslint:disable */
/* eslint-disable */
/**
 * MyWard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPaginatedUsersResponseDto } from '../models';
// @ts-ignore
import { AdminUpdateUserDto } from '../models';
// @ts-ignore
import { AdminUserResponseDto } from '../models';
/**
 * V1AdminUsersApi - axios parameter creator
 * @export
 */
export const V1AdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get single user by ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerGetUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUsersControllerGetUserById', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated by offset list of users
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {'asc' | 'desc'} [sortOrder] 
         * @param {'email' | 'nickname' | 'createdAt'} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerGetUsersList: async (limit?: number, page?: number, filter?: string, sortOrder?: 'asc' | 'desc', sortField?: 'email' | 'nickname' | 'createdAt', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user
         * @param {string} userId 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerUpdateUser: async (userId: string, adminUpdateUserDto: AdminUpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUsersControllerUpdateUser', 'userId', userId)
            // verify required parameter 'adminUpdateUserDto' is not null or undefined
            assertParamExists('adminUsersControllerUpdateUser', 'adminUpdateUserDto', adminUpdateUserDto)
            const localVarPath = `/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminUsersApi - functional programming interface
 * @export
 */
export const V1AdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get single user by ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerGetUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerGetUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated by offset list of users
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {'asc' | 'desc'} [sortOrder] 
         * @param {'email' | 'nickname' | 'createdAt'} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerGetUsersList(limit?: number, page?: number, filter?: string, sortOrder?: 'asc' | 'desc', sortField?: 'email' | 'nickname' | 'createdAt', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedUsersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerGetUsersList(limit, page, filter, sortOrder, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user
         * @param {string} userId 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerUpdateUser(userId: string, adminUpdateUserDto: AdminUpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerUpdateUser(userId, adminUpdateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminUsersApi - factory interface
 * @export
 */
export const V1AdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get single user by ID
         * @param {V1AdminUsersApiAdminUsersControllerGetUserByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerGetUserById(requestParameters: V1AdminUsersApiAdminUsersControllerGetUserByIdRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserResponseDto> {
            return localVarFp.adminUsersControllerGetUserById(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated by offset list of users
         * @param {V1AdminUsersApiAdminUsersControllerGetUsersListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerGetUsersList(requestParameters: V1AdminUsersApiAdminUsersControllerGetUsersListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedUsersResponseDto> {
            return localVarFp.adminUsersControllerGetUsersList(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.sortOrder, requestParameters.sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user
         * @param {V1AdminUsersApiAdminUsersControllerUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerUpdateUser(requestParameters: V1AdminUsersApiAdminUsersControllerUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserResponseDto> {
            return localVarFp.adminUsersControllerUpdateUser(requestParameters.userId, requestParameters.adminUpdateUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminUsersControllerGetUserById operation in V1AdminUsersApi.
 * @export
 * @interface V1AdminUsersApiAdminUsersControllerGetUserByIdRequest
 */
export interface V1AdminUsersApiAdminUsersControllerGetUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUsersApiAdminUsersControllerGetUserById
     */
    readonly userId: string
}

/**
 * Request parameters for adminUsersControllerGetUsersList operation in V1AdminUsersApi.
 * @export
 * @interface V1AdminUsersApiAdminUsersControllerGetUsersListRequest
 */
export interface V1AdminUsersApiAdminUsersControllerGetUsersListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminUsersApiAdminUsersControllerGetUsersList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminUsersApiAdminUsersControllerGetUsersList
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminUsersApiAdminUsersControllerGetUsersList
     */
    readonly filter?: string

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof V1AdminUsersApiAdminUsersControllerGetUsersList
     */
    readonly sortOrder?: 'asc' | 'desc'

    /**
     * 
     * @type {'email' | 'nickname' | 'createdAt'}
     * @memberof V1AdminUsersApiAdminUsersControllerGetUsersList
     */
    readonly sortField?: 'email' | 'nickname' | 'createdAt'
}

/**
 * Request parameters for adminUsersControllerUpdateUser operation in V1AdminUsersApi.
 * @export
 * @interface V1AdminUsersApiAdminUsersControllerUpdateUserRequest
 */
export interface V1AdminUsersApiAdminUsersControllerUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUsersApiAdminUsersControllerUpdateUser
     */
    readonly userId: string

    /**
     * 
     * @type {AdminUpdateUserDto}
     * @memberof V1AdminUsersApiAdminUsersControllerUpdateUser
     */
    readonly adminUpdateUserDto: AdminUpdateUserDto
}

/**
 * V1AdminUsersApi - object-oriented interface
 * @export
 * @class V1AdminUsersApi
 * @extends {BaseAPI}
 */
export class V1AdminUsersApi extends BaseAPI {
    /**
     * 
     * @summary Get single user by ID
     * @param {V1AdminUsersApiAdminUsersControllerGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public adminUsersControllerGetUserById(requestParameters: V1AdminUsersApiAdminUsersControllerGetUserByIdRequest, options?: AxiosRequestConfig) {
        return V1AdminUsersApiFp(this.configuration).adminUsersControllerGetUserById(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated by offset list of users
     * @param {V1AdminUsersApiAdminUsersControllerGetUsersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public adminUsersControllerGetUsersList(requestParameters: V1AdminUsersApiAdminUsersControllerGetUsersListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminUsersApiFp(this.configuration).adminUsersControllerGetUsersList(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.sortOrder, requestParameters.sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user
     * @param {V1AdminUsersApiAdminUsersControllerUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public adminUsersControllerUpdateUser(requestParameters: V1AdminUsersApiAdminUsersControllerUpdateUserRequest, options?: AxiosRequestConfig) {
        return V1AdminUsersApiFp(this.configuration).adminUsersControllerUpdateUser(requestParameters.userId, requestParameters.adminUpdateUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}
