/* tslint:disable */
/* eslint-disable */
/**
 * MyWard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPaginatedUserAssessmentsResponseDto } from '../models';
// @ts-ignore
import { AdminUserAssessmentResponseDto } from '../models';
/**
 * V1AdminUserAssessmentsApi - axios parameter creator
 * @export
 */
export const V1AdminUserAssessmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated user assessments
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {'asc' | 'desc'} [sortOrder] 
         * @param {Array<string>} [userIds] 
         * @param {'rate' | 'createdAt'} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserAssessmentsControllerGetPaginatedUserAssessments: async (limit?: number, page?: number, filter?: string, sortOrder?: 'asc' | 'desc', userIds?: Array<string>, sortField?: 'rate' | 'createdAt', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/user-assessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = Array.from(userIds);
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single user assessment by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserAssessmentsControllerGetUserAssessment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserAssessmentsControllerGetUserAssessment', 'id', id)
            const localVarPath = `/v1/admin/user-assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminUserAssessmentsApi - functional programming interface
 * @export
 */
export const V1AdminUserAssessmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminUserAssessmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get paginated user assessments
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {'asc' | 'desc'} [sortOrder] 
         * @param {Array<string>} [userIds] 
         * @param {'rate' | 'createdAt'} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserAssessmentsControllerGetPaginatedUserAssessments(limit?: number, page?: number, filter?: string, sortOrder?: 'asc' | 'desc', userIds?: Array<string>, sortField?: 'rate' | 'createdAt', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedUserAssessmentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAssessmentsControllerGetPaginatedUserAssessments(limit, page, filter, sortOrder, userIds, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single user assessment by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserAssessmentsControllerGetUserAssessment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserAssessmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAssessmentsControllerGetUserAssessment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminUserAssessmentsApi - factory interface
 * @export
 */
export const V1AdminUserAssessmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminUserAssessmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get paginated user assessments
         * @param {V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserAssessmentsControllerGetPaginatedUserAssessments(requestParameters: V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessmentsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedUserAssessmentsResponseDto> {
            return localVarFp.adminUserAssessmentsControllerGetPaginatedUserAssessments(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.sortOrder, requestParameters.userIds, requestParameters.sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single user assessment by ID
         * @param {V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserAssessmentsControllerGetUserAssessment(requestParameters: V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessmentRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserAssessmentResponseDto> {
            return localVarFp.adminUserAssessmentsControllerGetUserAssessment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminUserAssessmentsControllerGetPaginatedUserAssessments operation in V1AdminUserAssessmentsApi.
 * @export
 * @interface V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessmentsRequest
 */
export interface V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessments
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessments
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessments
     */
    readonly filter?: string

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessments
     */
    readonly sortOrder?: 'asc' | 'desc'

    /**
     * 
     * @type {Array<string>}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessments
     */
    readonly userIds?: Array<string>

    /**
     * 
     * @type {'rate' | 'createdAt'}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessments
     */
    readonly sortField?: 'rate' | 'createdAt'
}

/**
 * Request parameters for adminUserAssessmentsControllerGetUserAssessment operation in V1AdminUserAssessmentsApi.
 * @export
 * @interface V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessmentRequest
 */
export interface V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessmentRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessment
     */
    readonly id: string
}

/**
 * V1AdminUserAssessmentsApi - object-oriented interface
 * @export
 * @class V1AdminUserAssessmentsApi
 * @extends {BaseAPI}
 */
export class V1AdminUserAssessmentsApi extends BaseAPI {
    /**
     * 
     * @summary Get paginated user assessments
     * @param {V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserAssessmentsApi
     */
    public adminUserAssessmentsControllerGetPaginatedUserAssessments(requestParameters: V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetPaginatedUserAssessmentsRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminUserAssessmentsApiFp(this.configuration).adminUserAssessmentsControllerGetPaginatedUserAssessments(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.sortOrder, requestParameters.userIds, requestParameters.sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single user assessment by ID
     * @param {V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserAssessmentsApi
     */
    public adminUserAssessmentsControllerGetUserAssessment(requestParameters: V1AdminUserAssessmentsApiAdminUserAssessmentsControllerGetUserAssessmentRequest, options?: AxiosRequestConfig) {
        return V1AdminUserAssessmentsApiFp(this.configuration).adminUserAssessmentsControllerGetUserAssessment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
